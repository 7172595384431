export const localePrefix = '/:locale([a-zA-Z_-]{2,})';
// TODO: remove 'test-static-page-april-8' once testing is done
export const authenticatedStaticPages =
  '/:slug(xcover-protection-wallet|xcover-protection-wallet-coming-soon|test-static-page-april-8)';
export const authRoutes = [
  {
    key: 'account',
    path: `${localePrefix}/account`,
    exact: true,
  },
  {
    key: 'profile',
    path: `${localePrefix}/profile`,
    exact: true,
  },
  {
    key: 'payoutSubmitted',
    path: `${localePrefix}/account/payout/:payoutID/submitted`,
    exact: true,
  },
  {
    key: 'payout',
    path: `${localePrefix}/account/payout/:payoutID`,
    exact: true,
  },
  {
    key: 'paypalInterstitial',
    path: `${localePrefix}/paypal-connect`,
    exact: true,
  },
  {
    key: 'modifyBooking',
    path: `${localePrefix}/modify/:id`,
    exact: true,
  },
  {
    key: 'summary',
    path: `${localePrefix}/summary`,
    exact: true,
  },
  {
    key: 'authenticatedStaticPages',
    path: localePrefix + authenticatedStaticPages,
    exact: true,
  },
];
