import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { takeLatest, call, put, select, retry } from 'redux-saga/effects';
import { get } from 'lodash/fp';
import { Auth } from '@aws-amplify/auth';
import * as FullStory from '@fullstory/browser';
import { AnyAction } from 'redux';
import { decamelizeKeys } from 'humps';
import { push } from 'connected-react-router';
import { IMPERSONATOR_TOKEN, BOOKING_REFERENCE_FILED_ID } from '@whitelabel/helpers/constants';
import { captureExceptionWithFullStory } from '@whitelabel/helpers/utils';
import { IBooking } from '@whitelabel/helpers/types';
import api, {
  handleFormikAWSFormError,
  handleFormikFormError,
  ZENDESK_API_URL,
} from '@whitelabel/xcover-shared/helpers/api';
import {
  LANGUAGE_FILED_ID,
  CUSTOMER_RATING_FIELD_ID,
  MEDIA_TYPE,
  MAX_BOOKINGS_PER_REVIEW,
  WRONG_USERNAME_OR_PASSWORD,
  CUSTOMER_REGION,
  EU_CENTRAL_1,
} from '@whitelabel/xcover-shared/helpers/constants';
import { configureCognito, getAPIHost } from '@whitelabel/xcover-shared/helpers/multiRegion';
import { handleImpersonateLogin as impersonateLogin } from './impersonateLogin';

interface IUpdateCustomerGDPRProp {
  gdprConsent: boolean;
  id: string;
}

interface IUpdateBookingGDPRProp {
  gdprConsent: boolean;
  ins: string;
}

export const checkCustomer = createRoutine('customer/CHECK_CUSTOMER');
export const sendSignUpVerification = createRoutine('customer/SEND_SIGNUP_VERIFICATION');
export const validateSignUpToken = createRoutine('customer/VALIDATE_SIGNUP_TOKEN');
export const getCustomer = createRoutine('customer/GET_CUSTOMER');
export const updateCustomer = createRoutine('customer/UPDATE_CUSTOMER');
export const updateCustomerGDPR = createRoutine<IUpdateCustomerGDPRProp>('customer/UPDATE_CUSTOMER_GDPR');
export const processCustomerFeedback = createRoutine('customer/PROCESS_CUSTOMER_FEEDBACK');
export const processFeedbackFromEmail = createRoutine('customer/PROCESS_EMAIL_FEEDBACK');
export const updateBookingGDPR = createRoutine<IUpdateBookingGDPRProp>('bookings/UPDATE_BOOKING_GDPR');
export const updateEmail = createRoutine('customer/UPDATE_EMAIL');
export const transferBookings = createRoutine('customer/TRANSFER_BOOKINGS');
export const clearCustomer = createRoutine('customer/CLEAR_CUSTOMER');
export const getEmailUpdates = createRoutine('customer/GET_EMAIL_UPDATES');
export const resendVerification = createRoutine('customer/RESEND_VERIFICATION');
export const validateEmail = createRoutine('customer/VALIDATE_EMAIL');
export const getCustomerEmail = createRoutine('customer/GET_CUSTOMER_EMAIL');
export const updateCustomerPromiseRoutine = promisifyRoutine(updateCustomer);
export const updateEmailPromiseRoutine = promisifyRoutine(updateEmail);
export const checkCustomerPromiseCreator = promisifyRoutine(checkCustomer);
export const processCustomerFeedbackPromise = promisifyRoutine(processCustomerFeedback);
export const processFeedbackFromEmailPromise = promisifyRoutine(processFeedbackFromEmail);

export function* cognitoSignIn({
  payload: { cognitoUserID, password },
}: {
  payload: { cognitoUserID: string; password: string };
}) {
  try {
    // @ts-expect-error fix type for yield
    const cognitoUser = yield call([Auth, Auth.signIn], cognitoUserID, password);
    return cognitoUser;
  } catch (error) {
    // @ts-expect-error TS2571: Object is of type 'unknown'.
    if (error.message !== WRONG_USERNAME_OR_PASSWORD) {
      // @ts-expect-error TS2571: Object is of type 'unknown'.
      captureExceptionWithFullStory(error);
      // @ts-ignore don't care, gonna replace saga with rtk soon
      const generalErrorMessage = yield select(get('intl.messages.error'));
      throw new Error(generalErrorMessage);
    }

    throw error;
  }
}

const recordRatingOnFS = (email: string, rating: number, feedback: string) => {
  if (FullStory.isInitialized()) {
    FullStory.setUserVars({
      email,
    });
    FullStory.event('Review Added', {
      rating,
      comment: feedback,
    });
  }
};

const createZDTicket = async (
  firstName: string,
  lastName: string,
  email: string,
  locale: string,
  rating: number,
  feedback: string,
  bookingID = '',
  languageName: string,
  bookings = [],
  isFromEmail: boolean,
) => {
  const first20BookingIds = bookings.slice(0, MAX_BOOKINGS_PER_REVIEW).map((booking: IBooking) => booking.id);
  // ZD requires BCP 47 compliant language tags
  const langTag = ['zh-hans', 'zh-hant'].includes(locale) ? 'zh-cn' : locale;
  const body = JSON.stringify({
    request: {
      requester: { name: `${firstName} ${lastName}`, email, locale: langTag },
      subject: 'Xcover.com - New Customer Review added',
      comment: {
        body: `
        FS Session URL: ${
          !window.isHeadless && FullStory.isInitialized() ? FullStory.getCurrentSessionURL(true) : 'NA'
        }
        Customer Rating: ${rating}.
        Customer Feedback: ${feedback},
        Customer Email: ${email},
        ${
          isFromEmail
            ? `Booking ID: ${bookingID},`
            : `Booking Ids (Maximum ${MAX_BOOKINGS_PER_REVIEW}): ${first20BookingIds},`
        }
        `,
      },
      tags: isFromEmail ? ['xc-email-feedback'] : ['xc-widget-feedback'],
      custom_fields: [
        { id: LANGUAGE_FILED_ID, value: languageName || '' },
        { id: BOOKING_REFERENCE_FILED_ID, value: bookingID },
        { id: CUSTOMER_RATING_FIELD_ID, value: rating },
      ],
    },
  });
  await api.post(`${ZENDESK_API_URL}/requests`, false, { body });
};

function* handleCheckCustomer({
  payload: { requestValue, isEmail, setSubmitting, isFallback = false, suppressError = false, ignoreError = false },
}: any) {
  try {
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ language: locale });
    const mediaType = isEmail ? MEDIA_TYPE.EMAIL : MEDIA_TYPE.PHONE;
    const body = JSON.stringify({ [mediaType]: requestValue });
    if (isFallback) {
      searchParams.append('fallback', isFallback);
    }
    yield put(checkCustomer.request());
    // @ts-expect-error fix type for yield
    const customer = yield call(api.post, `${getAPIHost()}/customers/lookup/?${searchParams}`, false, { body });
    if (customer && isFallback) {
      localStorage.setItem(CUSTOMER_REGION, EU_CENTRAL_1);
      configureCognito();
    }
    yield put(checkCustomer.success({ ...customer, [mediaType]: requestValue }));
  } catch (error) {
    if (ignoreError) {
      // silent error, can be used for signup flow
      yield put(checkCustomer.failure(null));
    } else if (suppressError) {
      // Disable eslint to keep the original logic: not pass no-unused-vars
      // props down to child component.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      // @ts-expect-error TS2339: Property 'message' does not exist on type 'unknown'.
      const { message, ...supressedError } = error;
      yield put(checkCustomer.failure(supressedError));
    } else {
      yield put(checkCustomer.failure(handleFormikFormError(error)));
    }
  } finally {
    if (setSubmitting) setSubmitting(false);
    yield put(checkCustomer.fulfill());
  }
}

function* handleGetCustomerEmail({ payload: id }: AnyAction) {
  try {
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ language: locale });
    const headers = { 'X-Id': id };
    yield put(getCustomerEmail.request());
    // @ts-expect-error fix type for yield
    const customer = yield call(api.get, `${getAPIHost()}/customers/id/?${searchParams}`, false, { headers });
    yield put(getCustomerEmail.success(customer));
  } catch (error) {
    yield put(getCustomerEmail.failure(error));
  } finally {
    yield put(getCustomerEmail.fulfill());
  }
}

function* handleSendSignUpVerification({ payload: { requestValue, isEmail, setSubmitting } }: any) {
  try {
    const mediaType = isEmail ? MEDIA_TYPE.EMAIL : MEDIA_TYPE.PHONE;
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ language: locale });
    yield put(sendSignUpVerification.request());
    // @ts-expect-error fix type for yield
    const referrer = yield select(get('router.location.state.referrer'));
    const body = JSON.stringify({ [mediaType]: requestValue, referrer });
    yield call(api.post, `${getAPIHost()}/customers/signup/?${searchParams}`, false, { body });
    yield put(sendSignUpVerification.success(mediaType));
  } catch (error) {
    yield put(sendSignUpVerification.failure(handleFormikFormError(error)));
  } finally {
    setSubmitting(false);
    yield put(sendSignUpVerification.fulfill());
  }
}

function* handleValidateSignUpToken({
  payload: { id, token, source, onError, onSuccess, suppressExistingCustomerError = false },
}: any) {
  try {
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ language: locale });
    const body = JSON.stringify({ token, source });

    yield put(validateSignUpToken.request());
    // @ts-expect-error fix type for yield
    const customer = yield call(api.post, `${getAPIHost()}/customers/${id}/validate_token/?${searchParams}`, false, {
      body,
    });
    yield put(validateSignUpToken.success({ ...customer, id, token }));
    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch (error) {
    // @ts-expect-error TS2339: Property 'message' does not exist on type 'unknown'.
    if (error.status === 422 && suppressExistingCustomerError) {
      // Disable eslint to keep the original logic: not pass no-unused-vars
      // props down to child component.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      // @ts-expect-error TS2339: Property 'message' does not exist on type 'unknown'.
      const { message, ...suppressedError } = error;
      yield put(validateSignUpToken.failure(suppressedError));
    } else {
      // @ts-expect-error TS2339: Property 'message' does not exist on type 'unknown'.
      if (error.status === 404) {
        // @ts-expect-error fix type for yield
        const locale = yield select(get('intl.locale'));
        yield put(push(`/${locale}/login`));
      }
      yield put(validateSignUpToken.failure(error));
    }

    if (onError) {
      yield call(onError, error);
    }
  } finally {
    yield put(validateSignUpToken.fulfill());
  }
}

export function* handleGetCustomer({ payload: id }: AnyAction): Generator {
  try {
    const locale = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ language: locale as string });
    yield put(getCustomer.request());
    const customer: any = yield retry(3, 1000, api.get, `${getAPIHost()}/customers/${id}/?${searchParams}`, true);
    yield put(getCustomer.success(customer));
    const impersonate = sessionStorage.getItem(IMPERSONATOR_TOKEN);
    if (impersonate) {
      yield call<any>(impersonateLogin, { payload: { username: customer.id, email: customer.email } });
    }
  } catch (error) {
    yield put(getCustomer.failure(error));
    throw error;
  } finally {
    yield put(getCustomer.fulfill());
  }
}

export function* handleUpdateCustomer({ payload }: any) {
  try {
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ language: locale });
    const body = JSON.stringify(decamelizeKeys(payload));
    yield put(updateCustomer.request());
    // @ts-expect-error fix type for yield
    const customer = yield call(api.patch, `${getAPIHost()}/customers/${payload.id}/?${searchParams}`, true, {
      body,
    });
    yield put(updateCustomer.success(customer));
  } catch (error) {
    yield put(updateCustomer.failure(handleFormikFormError(error)));
  } finally {
    yield put(updateCustomer.fulfill());
  }
}

export function* handleProcessCustomerFeedback({ payload }: any) {
  const { rating, feedback = '', languageName, bookings } = payload;
  try {
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    // @ts-expect-error fix type for yield
    const customer = yield select(get('customer.data'));

    const { email, firstName, lastName, id } = customer;
    const searchParams = new URLSearchParams({ language: locale });
    if (!window.isHeadless) {
      recordRatingOnFS(email, rating, feedback);
    }
    yield put(processCustomerFeedback.request());
    if (ZENDESK_API_URL) {
      yield call(
        createZDTicket,
        firstName,
        lastName,
        email,
        locale,
        rating,
        feedback,
        '',
        languageName,
        bookings,
        false,
      );
    }
    yield call(api.patch, `${getAPIHost()}/customers/${id}/feedback/?${searchParams}`, true);
    yield put(processCustomerFeedback.success());
  } catch (error) {
    yield put(processCustomerFeedback.failure(handleFormikFormError(error)));
  } finally {
    yield put(processCustomerFeedback.fulfill());
  }
}

export function* handleProcessFeedbackFromEmail({ payload }: any) {
  const { rating, feedback = '', languageName, bookingID, firstName, lastName, email, securityToken } = payload;
  try {
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    if (!window.isHeadless) {
      recordRatingOnFS(email, rating, feedback);
    }
    yield put(processFeedbackFromEmail.request());
    if (ZENDESK_API_URL) {
      yield call(
        createZDTicket,
        firstName,
        lastName,
        email,
        locale,
        rating,
        feedback,
        bookingID,
        languageName,
        [],
        true,
      );
    }
    const updateCustomerParams = new URLSearchParams({ language: locale, security_token: securityToken });
    const updateCustomerBody = JSON.stringify({ feedback_given: true });
    yield call(
      api.patch,
      `${getAPIHost(bookingID)}/bookings/${bookingID}/update_customer/?${updateCustomerParams}`,
      false,
      {
        body: updateCustomerBody,
      },
    );
    yield put(processFeedbackFromEmail.success());
  } catch (error) {
    yield put(processFeedbackFromEmail.failure(handleFormikFormError(error)));
  } finally {
    yield put(processFeedbackFromEmail.fulfill());
  }
}

export function* handleUpdateCustomerGDPR({ payload: { gdprConsent, id } }: { payload: IUpdateCustomerGDPRProp }) {
  try {
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ language: locale });
    const body = JSON.stringify({ gdpr_consent: gdprConsent });
    yield put(updateCustomerGDPR.request());
    yield call(api.post, `${getAPIHost()}/customers/${id}/gdpr/?${searchParams}`, true, { body });
    yield put(
      updateCustomerGDPR.success({
        gdprConsent,
        id,
      }),
    );
  } catch (error) {
    yield put(updateCustomerGDPR.failure(handleFormikFormError(error)));
  } finally {
    yield put(updateCustomerGDPR.fulfill());
  }
}

export function* handleUpdateBookingGDPR({ payload: { gdprConsent, ins } }: { payload: IUpdateBookingGDPRProp }) {
  try {
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ language: locale });
    const body = JSON.stringify({ gdpr_consent: gdprConsent });
    yield put(updateBookingGDPR.request());
    yield call(api.patch, `${getAPIHost(ins)}/quotes/${ins}/gdpr/?${searchParams}`, false, { body });
    yield put(updateBookingGDPR.success({ gdprConsent, ins }));
  } catch (error: any) {
    // don't need to do anything
  } finally {
    yield put(updateBookingGDPR.fulfill());
  }
}

function* handleUpdateEmail({ payload }: any) {
  try {
    const { id, newEmail, password } = payload;
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ language: locale });
    const body = JSON.stringify({ email: newEmail });
    yield put(updateEmail.request());
    yield call(cognitoSignIn, { payload: { cognitoUserID: id, password } });
    yield call(api.post, `${getAPIHost()}/customers/${id}/email/?${searchParams}`, true, { body });
    yield put(updateEmail.success());
    yield put(getEmailUpdates());
  } catch (error) {
    // @ts-expect-error TS2339: Property 'message' does not exist on type 'unknown'.
    if (error.code === 'NotAuthorizedException') {
      yield put(updateEmail.failure(handleFormikAWSFormError(error)));
    } else {
      yield put(updateEmail.failure(handleFormikFormError(error)));
    }
  } finally {
    yield put(updateEmail.fulfill());
  }
}

export function* handleTransferBookings({ payload: { targetCustomerID, customerID, securityToken } }: any) {
  try {
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ language: locale });
    const body = JSON.stringify({ customer_id: customerID, security_token: securityToken });
    yield put(transferBookings.request());
    yield call(api.post, `${getAPIHost()}/customers/${targetCustomerID}/transfer_bookings/?${searchParams}`, true, {
      body,
    });
    yield put(transferBookings.success());
  } catch (error) {
    yield put(transferBookings.failure(error));
  } finally {
    yield put(transferBookings.fulfill());
  }
}

function* handleResendVerification({ payload: { id, email } }: any) {
  try {
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ language: locale });
    const body = JSON.stringify({ email });
    yield put(resendVerification.request());
    yield call(api.post, `${getAPIHost()}/customers/${id}/email/?${searchParams}`, true, { body });
    yield put(resendVerification.success());
  } catch (error) {
    yield put(resendVerification.failure(error));
  } finally {
    yield put(resendVerification.fulfill());
  }
}

function* getPendingEmailUpdates() {
  try {
    const { id } = yield select(get('customer.data'));
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ language: locale });
    const { email } = yield call(api.get, `${getAPIHost()}/customers/${id}/email/?${searchParams}`, true);
    yield put(getEmailUpdates.success(email));
  } catch (error) {
    yield put(getEmailUpdates.failure(error));
  }
}

function* handleEmailValidation({ payload: token }: AnyAction) {
  try {
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ language: locale });
    const body = JSON.stringify({ token });
    yield put(validateEmail.request());
    const { acknowledged: emailUpdated } = yield call(
      api.patch,
      `${getAPIHost()}/customers/email/update/?${searchParams}`,
      false,
      { body },
    );
    yield put(validateEmail.success(emailUpdated));
  } catch (error) {
    yield put(validateEmail.failure(error));
  } finally {
    yield put(validateEmail.fulfill());
  }
}

export function* customerSaga() {
  yield takeLatest(checkCustomer.TRIGGER, handleCheckCustomer);
  yield takeLatest(sendSignUpVerification.TRIGGER, handleSendSignUpVerification);
  yield takeLatest(validateSignUpToken.TRIGGER, handleValidateSignUpToken);
  yield takeLatest(getCustomer.TRIGGER, handleGetCustomer);
  yield takeLatest(updateCustomer.TRIGGER, handleUpdateCustomer);
  // @ts-ignore
  yield takeLatest(updateCustomerGDPR.TRIGGER, handleUpdateCustomerGDPR);
  yield takeLatest(processCustomerFeedback.TRIGGER, handleProcessCustomerFeedback);
  yield takeLatest(processFeedbackFromEmail.TRIGGER, handleProcessFeedbackFromEmail);
  // @ts-ignore
  yield takeLatest(updateBookingGDPR.TRIGGER, handleUpdateBookingGDPR);
  yield takeLatest(updateEmail.TRIGGER, handleUpdateEmail);
  yield takeLatest(transferBookings.TRIGGER, handleTransferBookings);
  yield takeLatest(getEmailUpdates.TRIGGER, getPendingEmailUpdates);
  yield takeLatest(resendVerification.TRIGGER, handleResendVerification);
  yield takeLatest(validateEmail.TRIGGER, handleEmailValidation);
  yield takeLatest(getCustomerEmail.TRIGGER, handleGetCustomerEmail);
}
