import styled from 'styled-components';
import { Col, NavItem as BSNavItem, NavLink as BSNavLink } from 'reactstrap';
import { fontStyle, resetLinkStyle } from '@whitelabel/helpers/style/type';
import { gray, themeColor, darkenColor } from '@whitelabel/helpers/style/colors';
import { mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { spacer, multiply } from '@whitelabel/helpers/style/units';
import { getThemeProp } from '@whitelabel/helpers/style/get';
import { plainLinkStyle } from '@whitelabel/xcover-shared/helpers/style/utils';

export const StyledFooter = styled.footer`
  margin-top: auto;
`;

const StyledSection = styled.div`
  padding: ${multiply(spacer, 1.5)} 0;

  ${mediaBreakpointUp('lg')`
    padding: ${multiply(spacer, 4)} 0;
  `}
`;

export const StyledSectionNav = styled(StyledSection)`
  border-top: ${getThemeProp('borderStyle')};
`;

export const StyledNavHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;

  ${mediaBreakpointUp('lg')`
    margin-bottom: ${multiply(spacer, 1.5)};
  `}
`;

export const StyledBrandLogo = styled.img`
  display: block;
  width: auto;
  max-width: 15rem;
  max-height: 2rem;
`;

export const StyledNavItem = styled(Col).attrs(() => ({ tag: BSNavItem, xs: 6 }))`
  ${fontStyle('contentMenu')}
  &:not(:last-child):not(:nth-last-of-type(2)) {
    margin-bottom: ${multiply(spacer, 0.25)};

    ${mediaBreakpointUp('md')`
      margin-bottom: ${multiply(spacer, 0.5)};
    `}

    ${mediaBreakpointUp('lg')`
      margin-bottom: ${multiply(spacer, 0.75)};
    `}
  }
`;

export const StyledNavLink = styled(BSNavLink)`
  ${resetLinkStyle}
  padding: 0;
  color: ${gray(700)};
  text-align: start;
  text-decoration: none;
  &:hover {
    color: ${darkenColor(0.1, themeColor('primary'))};
    ${plainLinkStyle}
  }
  &:focus {
    color: ${gray(700)};
  }
`;
