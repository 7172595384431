import { intlReducer as intl } from 'react-intl-redux';
import locales from '@whitelabel/reducers/locales';
import user from './user';
import customer from './customer';
import navs from './navs';
import quotes from './quotes';
import partners from './partners';
import feMessages from './intl';

const appReducers = {
  intl,
  locales,
  user,
  customer,
  navs,
  quotes,
  partners,
  feMessages,
};

export default appReducers;
