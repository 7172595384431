import { IHelpPagePartner } from '@whitelabel/xcover-shared/helpers/types';
import { getPartners, clearPartners } from '../actions/partners';

interface IState {
  data: {
    [locale: string]: IHelpPagePartner[];
  };
  loading: boolean;
  error: null | Error;
}

const initialState: IState = {
  data: {},
  loading: false,
  error: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case clearPartners.TRIGGER:
      return { ...initialState };

    case getPartners.REQUEST:
      return { ...state, loading: true, error: null };

    case getPartners.SUCCESS: {
      const { locale, partners } = action.payload;
      return { ...state, data: { ...state.data, [locale]: partners } };
    }

    case getPartners.FAILURE:
      return { ...state, error: action.payload };

    case getPartners.FULFILL:
      return { ...state, loading: false };

    default:
      return state;
  }
};
