import { all } from 'redux-saga/effects';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import { localesSaga } from '@whitelabel/actions/locales';
import { impersonateLoginSaga } from './impersonateLogin';
import { userSaga } from './user';
import { customerSaga } from './customer';
import { quotesSaga } from './quotes';
import { intlSaga } from './intl';
import { partnersSaga } from './partners';

export function* rootSaga() {
  yield all([
    localesSaga(),
    impersonateLoginSaga(),
    userSaga(),
    customerSaga(),
    routinePromiseWatcherSaga(),
    quotesSaga(),
    intlSaga(),
    partnersSaga(),
  ]);
}

export * from './impersonateLogin';
export * from './user';
export * from './customer';
export * from './quotes';
export * from './intl';
export * from './partners';
