import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Loading } from '@whitelabel/component-library';
import api from '@whitelabel/xcover-shared/helpers/api';
import { StyledLayout, StyledHeader, StyledMain } from './styledLayout';

const Footer = lazy(() => import('../../containers/layout/Footer'));

interface ILayoutProps {
  navBar?: React.ReactNode;
  footerType?: 'slim' | 'large';
  hideDisclaimer?: boolean;
  disclaimerSlug?: string;
  gray?: boolean;
  spacing?: 'lg';
  className?: string;
  children?: React.ReactNode;
  footer?: boolean | React.ReactElement;
  hideHeaderFooter?: boolean;
  hideFooterNav?: boolean;
  hasBottomBorder?: boolean;
  hasHeaderMarginBottom?: boolean;
}

const Layout = ({
  navBar,
  footerType = 'large',
  hideDisclaimer,
  disclaimerSlug = 'disclaimer',
  gray,
  spacing,
  className,
  children,
  footer,
  hideFooterNav,
  hasBottomBorder = true,
  hasHeaderMarginBottom = true,
  // rest of html attributes
  ...props
}: ILayoutProps): JSX.Element => {
  const { locale, bidi } = useIntl();
  const [disclaimer, setDisclaimer] = useState();

  useEffect(() => {
    const getDisclaimer = async () =>
      new Promise((resolve, reject) => {
        const disclaimerEndpoint = `${process.env.REACT_APP_MESSAGES_HOST}/staticpages/disclaimer`;

        api
          .get(`${disclaimerEndpoint}/?language=${locale}`, false, {})
          .then((disclaimerResponse) => {
            setDisclaimer(disclaimerResponse);
            resolve(disclaimerResponse);
          })
          .catch(() => {
            console.log('error fetching disclaimer');
            reject();
          });
      });

    void getDisclaimer();
  }, [locale]);

  return (
    <StyledLayout $rtl={bidi} $gray={gray} className={className} data-test-id="Layout" {...props}>
      {navBar && (
        <StyledHeader
          $hasHeaderMarginBottom={hasHeaderMarginBottom}
          $hasBottomBorder={hasBottomBorder}
          id="Header"
          $spacing={spacing}
          data-test-id="Header"
        >
          {navBar}
        </StyledHeader>
      )}

      <StyledMain id="Main" role="main" $spacing={spacing} data-test-id="Main">
        {children}
      </StyledMain>

      {footer ||
        (footer !== false && (
          <Suspense fallback={<Loading />}>
            <Footer
              footertype={footerType}
              hidedisclaimer={hideDisclaimer ? 'true' : 'false'}
              disclaimer={disclaimer}
              disclaimerSlug={disclaimerSlug}
              hidefooternav={hideFooterNav ? 'true' : 'false'}
            />
          </Suspense>
        ))}
    </StyledLayout>
  );
};

export default Layout;
