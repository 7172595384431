import { IError } from '@whitelabel/xcover-shared/helpers/types';
import { IUser } from '@whitelabel/helpers/types';
import {
  checkSession,
  login,
  logout,
  confirmSignUp,
  confirmUnmaskedSignUp,
  resendSignUp,
  forgotPassword,
  forgotPasswordSubmit,
  changePassword,
  resetError,
} from '../actions/user';
import { impersonateLogin } from '../actions/impersonateLogin';

export interface IUserState {
  data: null | IUser;
  loading: boolean;
  error: IError | null;
  checkingSession: boolean;
  forgotPasswordSubmitError: IError | null;
  signingIn: boolean;
}

export const initialState: IUserState = {
  data: null,
  loading: false,
  error: null,
  checkingSession: true,
  forgotPasswordSubmitError: null,
  signingIn: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case resetError.TRIGGER:
      return { ...state, error: null };

    case checkSession.REQUEST:
      return {
        ...state,
        data: null,
        checkingSession: true,
        error: null,
      };

    case login.REQUEST:
    case confirmSignUp.REQUEST:
    case confirmUnmaskedSignUp.REQUEST:
      return {
        ...state,
        data: null,
        error: null,
        loading: true,
        forgotPasswordSubmitError: null,
        signingIn: true,
      };
    case impersonateLogin.REQUEST:
    case resendSignUp.REQUEST:
    case forgotPassword.REQUEST:
    case forgotPasswordSubmit.REQUEST:
      return {
        ...state,
        data: null,
        error: null,
        loading: true,
        forgotPasswordSubmitError: null,
      };
    case logout.REQUEST:
    case changePassword.REQUEST:
      return {
        ...state,
        error: null,
      };

    case checkSession.SUCCESS:
    case login.SUCCESS:
    case impersonateLogin.SUCCESS:
    case confirmSignUp.SUCCESS:
      return { ...state, data: action.payload };

    case checkSession.FAILURE:
    case logout.SUCCESS:
      return { ...state, data: null };

    case login.FAILURE:
    case impersonateLogin.FAILURE:
    case logout.FAILURE:
    case confirmSignUp.FAILURE:
    case confirmUnmaskedSignUp.FAILURE:
    case resendSignUp.FAILURE:
    case forgotPassword.FAILURE:
    case changePassword.FAILURE:
      return { ...state, error: action.payload };

    case forgotPasswordSubmit.FAILURE:
      return { ...state, forgotPasswordSubmitError: action.payload };

    case login.FULFILL:
    case confirmSignUp.FULFILL:
    case confirmUnmaskedSignUp.FULFILL:
      return { ...state, loading: false, signingIn: false };

    case impersonateLogin.FULFILL:
    case resendSignUp.FULFILL:
    case forgotPassword.FULFILL:
    case forgotPasswordSubmit.FULFILL:
      return { ...state, loading: false };

    case checkSession.FULFILL:
      return { ...state, checkingSession: false };

    default:
      return state;
  }
};
