import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { Auth } from '@aws-amplify/auth';
import { get, mapKeys, camelCase } from 'lodash/fp';
import * as FullStory from '@fullstory/browser';
import { AnyAction } from 'redux';
import { FormikHelpers, FormikValues } from 'formik';
import { IMPERSONATOR_TOKEN } from '@whitelabel/helpers/constants';
import {
  ERROR_CODE,
  IMPERSONATE,
  KW_FE_MESSAGES_SLUG,
  SOURCE_EMAIL,
  SOURCE_SMS,
  SKIP_SUMMARY_PAGE,
  CUSTOMER_REGION,
  ZENDESK_CHAT_PARTNER,
} from '@whitelabel/xcover-shared/helpers/constants';
import api, { handleFormikAWSFormError } from '@whitelabel/xcover-shared/helpers/api';
import { getAPIHost, getStoredCustomerRegion } from '@whitelabel/xcover-shared/helpers/multiRegion';
import { isXCoverNextJSLive } from '@whitelabel/xcover-shared/helpers/utils';
import { ICustomer } from '@whitelabel/helpers/types';
import { hasCookieYesMarketingAccepted } from '../helpers/utils';
import { clearPDS } from '../store/services/xcover/endpoints/pds';
import { SignUpError } from '../helpers/types';
import { clearBookings } from '../store/services/xcover/endpoints/bookings';
import { clearHelpArticle } from '../store/services/xcover/endpoints/help';
import { clearCOI } from '../store/services/xcover/endpoints/coi';
import {
  handleGetCustomer as getCustomer,
  clearCustomer,
  handleTransferBookings as transferBookings,
  handleUpdateCustomerGDPR as updateCustomerGDPR,
  cognitoSignIn,
} from './customer';
import { clearQuotes, handleGetCustomerQuotes as getCustomerQuotes } from './quotes';
import { clearPartners } from './partners';
import { handleLoadFEMessages as loadFEMessages } from './intl';

export const checkSession = createRoutine('user/CHECK_SESSION');
export const login = createRoutine('user/LOGIN');
export const logout = createRoutine('user/LOGOUT');
export const confirmSignUp = createRoutine('user/CONFIRM_SIGNUP');
export const confirmUnmaskedSignUp = createRoutine('user/CONFIRM_UNMASKED_SIGNUP');
export const resendSignUp = createRoutine('user/RESEND_SIGNUP');
export const forgotPassword = createRoutine('user/FORGOT_PASSWORD');
export const forgotPasswordSubmit = createRoutine('user/FORGOT_PASSWORD_SUBMIT');
export const changePassword = createRoutine('user/CHANGE_PASSWORD');
export const resetError = createRoutine('user/RESET_USER_ERROR');

export const forgotPasswordPromiseRoutine = promisifyRoutine(forgotPassword);
export const getUserAttributes = (cognitoUser: any) =>
  mapKeys(camelCase, {
    username: cognitoUser.username,
    ...cognitoUser.attributes,
  });
const clearImpersonateValues = () => {
  sessionStorage.removeItem(IMPERSONATE.CUSTOMER_ID);
  sessionStorage.removeItem(IMPERSONATE.CUSTOMER_EMAIL);
  sessionStorage.removeItem(IMPERSONATOR_TOKEN);
  sessionStorage.removeItem(IMPERSONATE.ADMIN_USERNAME);
  localStorage.removeItem(CUSTOMER_REGION);
};

function identifyUser(
  username: string,
  { id, firstName, lastName, email }: { id: string; firstName: string; lastName: string; email: string },
) {
  if (!window.isHeadless) {
    window.bwtag('identify', username);
    if (FullStory.isInitialized()) {
      FullStory.identify(id, {
        displayName: `${firstName} ${lastName}`,
        email,
      });
    }
  }
}

function* handleCheckSession() {
  try {
    const id = sessionStorage.getItem(IMPERSONATE.CUSTOMER_ID);
    const email = sessionStorage.getItem(IMPERSONATE.CUSTOMER_EMAIL);
    // check session for 'login as customer' flow
    if (id) {
      yield put(checkSession.request());
      const user = {
        username: id,
        email,
      };
      yield call<any>(getCustomer, { payload: user.username });
      yield put(checkSession.success(user));
    } else if (getStoredCustomerRegion()) {
      // usual check session flow
      yield put(checkSession.request());
      // @ts-expect-error fix type for yield
      const cognitoUser = yield call([Auth, Auth.currentAuthenticatedUser]);
      const user = getUserAttributes(cognitoUser);
      yield call<any>(getCustomer, { payload: user.username });
      yield put(checkSession.success(user));
    }
  } catch (error) {
    yield put(checkSession.failure());
  } finally {
    yield put(checkSession.fulfill());
  }
}

function* redirectAfterLogIn({
  redirect,
  redirectParam,
  locale,
  customer,
}: {
  redirect?: { pathname: string };
  redirectParam?: string | null;
  locale: string;
  customer: ICustomer;
}) {
  if (redirectParam) {
    // Trigger a reload so the request goes to the nextJs server
    window.location.replace(redirectParam);
  } else if (!redirect?.pathname || redirect.pathname?.endsWith('/account')) {
    try {
      // @ts-expect-error fix type for yield
      const quotes = yield call<any>(getCustomerQuotes, { payload: { id: customer.id, throwError: true } });

      if (
        quotes &&
        Object.keys(quotes).length &&
        !Object.keys(quotes).includes(localStorage.getItem(SKIP_SUMMARY_PAGE) as string)
      ) {
        yield call<any>(loadFEMessages, { payload: { slug: KW_FE_MESSAGES_SLUG } });
        yield put(push(`/${locale}/summary`));
      } else {
        yield put(push(`/${locale}/account`));
      }
    } catch (error) {
      if ((error as { status: number }).status === 404) {
        yield put(push(`/${locale}/account`));
      } else {
        yield put(push(`/${locale}/summary`));
      }
    }
  } else {
    yield put(push(redirect));
  }
}

function* updateGDPRConsent(customer: ICustomer) {
  const gdprConsent = hasCookieYesMarketingAccepted();
  if ((gdprConsent && !customer.gdprConsent) || (!gdprConsent && customer.gdprConsent)) {
    yield call(updateCustomerGDPR, {
      payload: {
        id: customer.id,
        gdprConsent,
      },
    });
  }
}

function* handleLogin({
  payload: {
    cognitoUserID,
    password,
    setSubmitting,
    transferBookingsData = null,
    shouldRedirect = true,
    callbackOnSuccess,
  },
}: {
  payload: {
    cognitoUserID: string;
    password: string;
    setSubmitting?: FormikHelpers<FormikValues>['setSubmitting'];
    transferBookingsData?: { customerID: string; securityToken: string } | null;
    shouldRedirect?: boolean;
    callbackOnSuccess?: () => void;
  };
}) {
  try {
    sessionStorage.removeItem(IMPERSONATOR_TOKEN);
    yield put(login.request());

    // @ts-expect-error fix type for yield
    const cognitoUser = yield call(cognitoSignIn, { payload: { cognitoUserID, password } });

    const user = getUserAttributes(cognitoUser);

    yield call<any>(getCustomer, { payload: user.username });
    // @ts-expect-error fix type for yield
    const customer = yield select(get('customer.data'));

    identifyUser(user.username, customer);

    if (transferBookingsData) {
      yield call(transferBookings, {
        payload: {
          targetCustomerID: customer.id,
          customerID: transferBookingsData.customerID,
          securityToken: transferBookingsData.securityToken,
        },
      });
    }

    yield call(updateGDPRConsent, customer);
    yield put(login.success(user));

    if (callbackOnSuccess) {
      setSubmitting?.(false);
      callbackOnSuccess();
    } else if (shouldRedirect) {
      // @ts-expect-error fix type for yield
      const locale = yield select(get('intl.locale'));
      // @ts-expect-error fix type for yield
      const redirect = yield select(get('router.location.state.referrer'));
      // @ts-expect-error fix type for yield
      const search = yield select(get('router.location.search'));
      const redirectParam = new URLSearchParams(search).get('redirect');
      yield call(redirectAfterLogIn, { redirect, locale, customer, redirectParam });
    } else {
      setSubmitting?.(false);
    }
  } catch (error) {
    yield put(login.failure(handleFormikAWSFormError(error)));
    setSubmitting?.(false);
  } finally {
    yield put(login.fulfill());
  }
}

function* handleLogout({ payload: redirect }: AnyAction) {
  try {
    yield put(logout.request());
    yield call([Auth, Auth.signOut]);

    if (!window.isHeadless) window.bwtag('reset');
    if (sessionStorage.getItem(IMPERSONATOR_TOKEN)) clearImpersonateValues();

    sessionStorage.removeItem(ZENDESK_CHAT_PARTNER);

    if (redirect && isXCoverNextJSLive()) {
      window.location.replace(redirect);
    }
    // clearCustomer needs to put after clearPartners,
    // or else, it might re-fetch partners after logout on help page
    yield put(clearPartners());
    yield put(clearCustomer());
    yield put(clearBookings());
    yield put(clearQuotes());
    yield put(clearCOI());
    yield put(clearPDS());
    yield put(clearHelpArticle());
    yield put(logout.success());

    if (redirect) {
      yield put(push(redirect));
    }
  } catch (error) {
    yield put(logout.failure(error));
  } finally {
    yield put(logout.fulfill());
  }
}

function* signUpWithJWTToken({
  id,
  signUpToken,
  password,
  email,
  phone,
  locale,
  securityToken,
}: {
  id: string;
  email?: string;
  password: string;
  phone?: string;
  signUpToken: string;
  locale: string;
  securityToken: string;
}) {
  const searchParams = new URLSearchParams({ language: locale, security_token: securityToken });
  const body: { token: string; password: string; email?: string; phone?: string } = {
    token: signUpToken,
    password,
  };
  if (email) {
    body.email = email;
  }
  if (phone) {
    body.phone = phone;
  }
  yield call(api.post, `${getAPIHost()}/customers/${id}/signup_confirm/?${searchParams}`, false, {
    body: JSON.stringify(body),
  });
}

function* signUp({
  source,
  password,
  email,
  phone,
  id,
  token,
  locale,
}: {
  source: typeof SOURCE_EMAIL | typeof SOURCE_SMS;
  password: string;
  email?: string;
  phone?: string;
  id: string;
  token: string;
  locale: string;
}) {
  const searchParams = new URLSearchParams({ language: locale, security_token: token });
  const body: {
    source: typeof SOURCE_EMAIL | typeof SOURCE_SMS;
    password: string;
    email?: string;
    phone?: string;
  } = {
    source,
    password,
  };
  if (email) {
    body.email = email;
  }
  if (phone) {
    body.phone = phone;
  }
  yield call(api.post, `${getAPIHost()}/customers/${id}/account/?${searchParams}`, false, {
    body: JSON.stringify(body),
  });
}

function* handleConfirmSignUp({
  payload: { id, email, password, phone, setSubmitting, source, setErrors, signUpToken },
}: {
  payload: {
    id: string;
    email?: string;
    password: string;
    phone?: string;
    setSubmitting: FormikHelpers<FormikValues>['setSubmitting'];
    source: typeof SOURCE_EMAIL | typeof SOURCE_SMS;
    setErrors: (errors: SignUpError) => void;
    signUpToken: string | null;
  };
}) {
  try {
    yield put(confirmSignUp.request());
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    const urlParams = new URLSearchParams(yield select(get('router.location.search')));
    const token = urlParams.get('token');
    const securityToken = urlParams.get('securityToken');
    // @ts-expect-error fix type for yield
    const redirect = yield select(get('router.location.state.referrer'));
    const { search, pathname } = redirect || {};
    const stateSearch = new URLSearchParams(search);
    stateSearch.delete('signup_token');
    stateSearch.delete('id');
    const redirectState = {
      pathname,
      search: stateSearch.toString() ? `?${stateSearch.toString()}` : '',
    };

    if (signUpToken) {
      yield call(signUpWithJWTToken, {
        id,
        email,
        password,
        phone,
        signUpToken,
        locale,
        securityToken: securityToken!,
      });
    } else {
      yield call(signUp, { source, password, email, phone, id, token: token!, locale });
    }
    // @ts-expect-error fix type for yield
    const cognitoUser = yield call(cognitoSignIn, { payload: { cognitoUserID: id, password } });
    const user = getUserAttributes(cognitoUser);
    yield put(confirmSignUp.success(user));

    yield call<any>(getCustomer, { payload: user.username });
    // @ts-expect-error fix type for yield
    const customer = yield select(get('customer.data'));

    identifyUser(user.username, customer);
    yield call(updateGDPRConsent, customer);
    yield call(redirectAfterLogIn, { redirect: redirectState, locale, customer });
  } catch (error) {
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    // @ts-expect-error TS2571: Object is of type 'unknown'.
    if (error.code === ERROR_CODE.ALREADY_REGISTERED) {
      yield put(push(`/${locale}/login`));
    }
    // @ts-expect-error TS2571: Object is of type 'unknown'.
    const errors = error?.errors;
    if (errors && !errors._error) {
      if (errors.token === 'Invalid token: ExpiredSignatureError') {
        setErrors({ showTokenError: true });
        // eslint-disable-next-line no-underscore-dangle
      } else if (errors._non_field_errors) {
        // eslint-disable-next-line no-underscore-dangle
        setErrors({ phone: errors._non_field_errors });
      } else {
        setErrors(errors);
      }
    } else {
      setErrors({ showCommonError: true });
    }
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    setSubmitting(false);
  } finally {
    yield put(confirmSignUp.fulfill());
  }
}

function* handleUnmaskedConfirmSignUp({
  payload: { id, email, password, phone, source, unmaskedSignupToken, onSuccess, onError },
}: {
  payload: {
    id: string;
    email?: string;
    password: string;
    phone?: string;
    source: typeof SOURCE_EMAIL | typeof SOURCE_SMS;
    unmaskedSignupToken: string;
    onSuccess: () => void;
    onError: (error: any) => void;
  };
}) {
  try {
    yield put(confirmUnmaskedSignUp.request());
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));

    yield call(signUp, { source, password, email, phone, id, token: unmaskedSignupToken, locale });

    yield put(confirmUnmaskedSignUp.success());

    yield call(onSuccess);
  } catch (error) {
    yield put(confirmUnmaskedSignUp.failure(error));
    yield call(onError, error);
  } finally {
    yield put(confirmUnmaskedSignUp.fulfill());
  }
}

function* handleResendSignUp({ payload: email }: AnyAction) {
  try {
    yield put(resendSignUp.request());
    yield call([Auth, Auth.resendSignUp], email);
    yield put(resendSignUp.success());
  } catch (error) {
    yield put(resendSignUp.failure(error));
  } finally {
    yield put(resendSignUp.fulfill());
  }
}

function* handleForgotPassword({ payload: { isPhone, value } }: any) {
  try {
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ language: locale });
    const body = JSON.stringify({ [isPhone ? 'phone' : 'email']: value });
    yield put(forgotPassword.request());
    yield call(api.post, `${getAPIHost()}/customers/forgot_password_initiate/?${searchParams}`, false, { body });
    yield put(forgotPassword.success());
  } catch (error) {
    yield put(forgotPassword.failure(handleFormikAWSFormError(error)));
  } finally {
    yield put(forgotPassword.fulfill());
  }
}

function* handleForgotPasswordSubmit({ payload: { id, password, passwordToken, setSubmitting } }: any) {
  try {
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ language: locale });
    const body = JSON.stringify({ token: passwordToken, password });
    yield put(forgotPasswordSubmit.request());
    yield call(api.post, `${getAPIHost()}/customers/${id}/forgot_password_confirm/?${searchParams}`, false, { body });
    yield call(handleLogin, { payload: { cognitoUserID: id, password, setSubmitting } });
    yield put(forgotPasswordSubmit.success());
  } catch (error) {
    yield put(forgotPasswordSubmit.failure(handleFormikAWSFormError(error)));
  } finally {
    setSubmitting(false);
    yield put(forgotPasswordSubmit.fulfill());
  }
}

export function* userSaga() {
  yield takeLatest(checkSession.TRIGGER, handleCheckSession);
  yield takeLatest<any>(login.TRIGGER, handleLogin);
  yield takeLatest(logout.TRIGGER, handleLogout);
  yield takeLatest<any>(confirmSignUp.TRIGGER, handleConfirmSignUp);
  yield takeLatest<any>(confirmUnmaskedSignUp.TRIGGER, handleUnmaskedConfirmSignUp);
  yield takeLatest(resendSignUp.TRIGGER, handleResendSignUp);
  yield takeLatest(forgotPassword.TRIGGER, handleForgotPassword);
  yield takeLatest(forgotPasswordSubmit.TRIGGER, handleForgotPasswordSubmit);
}
