import { COOKIE_YES_MODAL_ACTIONED } from '@whitelabel/xcover-shared/helpers/constants';
import { IError } from '@whitelabel/xcover-shared/helpers/types';
import { ICustomer } from '@whitelabel/helpers/types';
import {
  checkCustomer,
  sendSignUpVerification,
  validateSignUpToken,
  getCustomer,
  updateCustomer,
  clearCustomer,
  getEmailUpdates,
  getCustomerEmail,
  validateEmail,
  updateCustomerGDPR,
  updateBookingGDPR,
  transferBookings,
  processCustomerFeedback,
} from '../actions/customer';

export interface ICustomerState {
  data: null | ICustomer;
  loading: boolean;
  emailUpdated: boolean;
  error: IError | null;
  validateSignUpTokenError: IError | null;
  customerChecked: boolean;
  signUpVerificationSentType: null | 'email' | 'phone';
  isSignupTokenValid: boolean;
  [COOKIE_YES_MODAL_ACTIONED]?: boolean;
}

export const initialState: ICustomerState = {
  data: null,
  loading: false,
  emailUpdated: false,
  error: null,
  validateSignUpTokenError: null,
  customerChecked: false,
  signUpVerificationSentType: null,
  isSignupTokenValid: false,
  [COOKIE_YES_MODAL_ACTIONED]: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case clearCustomer.TRIGGER:
      return { ...initialState };

    case checkCustomer.REQUEST:
      return {
        ...state,
        loading: true,
        customerChecked: false,
        error: null,
      };

    case sendSignUpVerification.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        signUpVerificationSentType: null,
      };

    case validateSignUpToken.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        validateSignUpTokenError: null,
      };

    case transferBookings.REQUEST:
    case getCustomer.REQUEST:
    case getCustomerEmail.REQUEST:
    case updateCustomerGDPR.REQUEST:
    case validateEmail.REQUEST:
      return { ...state, loading: true, error: null };
    case updateCustomer.REQUEST:
      return { ...state, loading: false, error: null };
    case 'UPDATE_COOKIE_MODAL_ACTIONED': {
      return { ...state, [COOKIE_YES_MODAL_ACTIONED]: true };
    }
    case checkCustomer.SUCCESS:
    case getCustomer.SUCCESS:
    case getCustomerEmail.SUCCESS:
    case updateCustomer.SUCCESS:
      return { ...state, data: { ...state.data, ...action.payload } };

    case validateSignUpToken.SUCCESS:
      return { ...state, data: { ...state.data, ...action.payload }, isSignupTokenValid: true };

    case processCustomerFeedback.SUCCESS:
      return { ...state, data: { ...state.data, feedbackGiven: true } };

    case updateCustomerGDPR.SUCCESS:
    case updateBookingGDPR.SUCCESS:
      return { ...state, data: { ...state.data, gdprConsent: action.payload.gdprConsent } };

    case sendSignUpVerification.SUCCESS:
      return { ...state, signUpVerificationSentType: action.payload };

    case getEmailUpdates.SUCCESS:
      return { ...state, data: { ...state.data, pendingEmail: action.payload } };

    case validateEmail.SUCCESS:
      return { ...state, emailUpdated: action.payload };

    case checkCustomer.FAILURE:
      return { ...state, data: null, error: action.payload };

    case sendSignUpVerification.FAILURE:
    case getCustomer.FAILURE:
    case getCustomerEmail.FAILURE:
    case updateCustomer.FAILURE:
    case updateCustomerGDPR.FAILURE:
    case validateEmail.FAILURE:
      return { ...state, error: action.payload };

    case validateSignUpToken.FAILURE:
      return { ...state, validateSignUpTokenError: action.payload };

    case sendSignUpVerification.FULFILL:
    case getCustomer.FULFILL:
    case getCustomerEmail.FULFILL:
    case updateCustomer.FULFILL:
    case updateCustomerGDPR.FULFILL:
    case transferBookings.FULFILL:
    case validateEmail.FULFILL:
      return { ...state, loading: false };

    case validateSignUpToken.FULFILL:
      return { ...state, loading: false };

    case checkCustomer.FULFILL:
      return { ...state, loading: false, customerChecked: true };

    default:
      return state;
  }
};
