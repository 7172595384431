import { mergeAll, get, union } from 'lodash/fp';
import {
  getQuote,
  createQuote,
  getCustomerQuotes,
  updateCustomerQuotes,
  resetUpdateQuotesError,
  updatePolicyHolderQuote,
  clearQuotes,
  updateQuote,
} from '../actions/quotes';

interface IState {
  data: {
    [locale: string]: {
      all: string[];
      quotes?: Record<string, any>;
      next: any;
    };
  };
  loading: boolean;
  error: null | Error;
  updating: boolean;
  updatingQuote: boolean;
  updatingQuoteError: any;
}

const initialState: IState = {
  data: {},
  loading: false,
  updatingQuote: false,
  updatingQuoteError: null,
  updating: false,
  error: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case clearQuotes.TRIGGER:
      return { ...initialState };

    case resetUpdateQuotesError.TRIGGER:
      return { ...state, updatingQuoteError: null };
    case getQuote.REQUEST:
    case createQuote.REQUEST:
    case getCustomerQuotes.REQUEST:
      return { ...state, loading: true, error: null };

    case updateCustomerQuotes.REQUEST:
    case updatePolicyHolderQuote.REQUEST:
      return { ...state, updatingQuote: true, updatingQuoteError: null };

    case updateQuote.REQUEST:
      return { ...state, updating: true, error: null };

    case getQuote.SUCCESS:
    case createQuote.SUCCESS:
    case updateQuote.SUCCESS: {
      const { locale, id, quotePackage } = action.payload;
      const newState = {
        data: {
          [locale]: {
            quotePackages: {
              [id]: quotePackage,
            },
          },
        },
      };

      return mergeAll([{}, state, newState]);
    }
    case getCustomerQuotes.SUCCESS: {
      const { locale, quotes, all, next } = action.payload;
      const prevQuotes = get(`data.${locale}.quotes`, state);
      const prevAll = get(`data.${locale}.all`, state);

      return {
        ...state,
        data: {
          ...state.data,
          [locale]: {
            quotes: { ...prevQuotes, ...quotes },
            all: union(all, prevAll),
            next,
          },
        },
      };
    }

    case updatePolicyHolderQuote.SUCCESS:
    case updateCustomerQuotes.SUCCESS: {
      const { id, locale, result } = action.payload;

      const updatedState = {
        data: {
          [locale]: {
            quotes: {
              [id]: result,
            },
          },
        },
      };

      return mergeAll([{}, state, updatedState]);
    }

    case getQuote.FAILURE:
    case createQuote.FAILURE:
    case getCustomerQuotes.FAILURE:
    case updateQuote.FAILURE:
      return { ...state, error: action.payload };

    case updateCustomerQuotes.FAILURE:
      return { ...state, updatingQuoteError: action.payload };

    case getQuote.FULFILL:
    case createQuote.FULFILL:
    case getCustomerQuotes.FULFILL:
      return { ...state, loading: false };

    case updateQuote.FULFILL:
      return { ...state, loading: false, updating: false };

    case updateCustomerQuotes.FULFILL:
    case updatePolicyHolderQuote.FULFILL:
      return { ...state, updatingQuote: false };

    default:
      return state;
  }
};
