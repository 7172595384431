import { createRoutine, promisifyRoutine } from 'redux-saga-routines';
import { get } from 'lodash/fp';
import { takeLatest, put, call, select } from 'redux-saga/effects';
import { decamelizeKeys } from 'humps';

import api, { API_HOST } from '@whitelabel/xcover-shared/helpers/api';
import { SECURITY_TOKEN, POSTCODE_PARAM } from '@whitelabel/xcover-shared/helpers/constants';
import { getAPIHost } from '@whitelabel/xcover-shared/helpers/multiRegion';
import { POLICY_TYPE } from '@whitelabel/helpers/constants';
import { storeKWAgentInfo } from '@whitelabel/xcover-shared/helpers/hubspot';

export const createQuote = createRoutine('quotes/CREATE_QUOTE');
export const getQuote = createRoutine('quotes/GET_QUOTE');
export const clearQuotes = createRoutine('bookings/CLEAR_QUOTES');
export const getCustomerQuotes = createRoutine('quotes/GET_CUSTOMER_QUOTES');
export const updatePolicyHolderQuote = createRoutine('quotes/UPDATE_POLICY_HOLDER_QUOTE');
export const updateCustomerQuotes = createRoutine('quotes/UPDATE_CUSTOMER_QUOTES');
export const resetUpdateQuotesError = createRoutine('quotes/RESET_UPDATE_QUOTES_ERROR');
export const updateQuote = createRoutine('quotes/UPDATE_QUOTE');

export const createQuotePromiseCreator = promisifyRoutine(createQuote);
export const updateQuotePromiseCreator = promisifyRoutine(updateQuote);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* handleCreateQuote({ payload }: any) {
  try {
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    const body = JSON.stringify(payload);
    yield put(createQuote.request());
    // @ts-expect-error fix type for yield
    const quotePackage = yield call(api.post, `${API_HOST}/v1/quotes/?language=${locale}`, false, { body });

    if (
      !quotePackage.quotes[0]?.attributes.category &&
      quotePackage.quotes[0]?.policy.policyType === POLICY_TYPE.CYBER_INSURANCE
    ) {
      quotePackage.quotes[0].attributes.category = POLICY_TYPE.CYBER_INSURANCE;
    }

    yield put(createQuote.success({ id: quotePackage.id, locale, quotePackage }));
  } catch (error) {
    yield put(createQuote.failure(error));
  } finally {
    yield put(createQuote.fulfill());
  }
}

function* handleGetQuote({ payload: id }: { payload: string; type: string }) {
  try {
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    // @ts-expect-error fix type for yield
    const search = yield select(get('router.location.search'));
    const urlParams = new URLSearchParams(search);
    const postcodeParam = urlParams.get(POSTCODE_PARAM);
    if (postcodeParam) {
      sessionStorage.setItem(
        'quoteValues',
        JSON.stringify({
          address: {
            country: 'AU',
            postcode: atob(postcodeParam),
          },
        }),
      );
    }
    const securityToken = urlParams.get(SECURITY_TOKEN);
    const queryString = securityToken ? `?language=${locale}&security_token=${securityToken}` : `?language=${locale}`;
    yield put(getQuote.request());
    // @ts-expect-error fix type for yield
    const quotePackage = yield call(api.get, `${getAPIHost(id)}/quotes/${id}/${queryString}`, false);
    yield put(getQuote.success({ id, locale, quotePackage }));
  } catch (error) {
    yield put(getQuote.failure(error));
  } finally {
    yield put(getQuote.fulfill());
  }
}

export function* handleGetCustomerQuotes({
  payload: { id, throwError },
}: {
  payload: { id: string; throwError?: boolean };
  type: string;
}) {
  try {
    const locale: string = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ language: locale });
    yield put(getCustomerQuotes.request());
    const { results, next } = yield call(api.get, `${getAPIHost()}/customers/${id}/quotes/?${searchParams}`, true);
    if (results[0]) storeKWAgentInfo(results[0]);

    const all: string[] = [];
    const quotes = results.reduce((allQuotes: any, quote: any) => {
      all.push(quote.id);
      return { ...allQuotes, [quote.id]: quote };
    }, {});
    yield put(
      getCustomerQuotes.success({
        locale,
        quotes,
        all,
        next,
      }),
    );
    return quotes;
  } catch (error) {
    yield put(getCustomerQuotes.failure(error));
    if (throwError) {
      throw error;
    }
    return null;
  } finally {
    yield put(getCustomerQuotes.fulfill());
  }
}

function* handleUpdatePolicyHolderQuote({
  payload: { id, securityToken, fields, onSuccess, onError },
}: {
  payload: {
    id: string;
    securityToken: string;
    fields: {
      policy_type: string;
      quote_id: string;
      policyholder: {
        first_name?: string;
        last_name?: string;
        phone?: string;
      };
    };
    onSuccess: () => void;
    onError: () => void;
  };
  type: string;
}) {
  try {
    const locale: string = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ language: locale, security_token: securityToken });
    const body = JSON.stringify(fields);

    yield put(updatePolicyHolderQuote.request());
    // @ts-expect-error fix type for yield
    const result = yield call(api.patch, `${getAPIHost()}/quotes/${id}/?${searchParams}`, true, { body });

    yield put(updatePolicyHolderQuote.success({ locale, id, result }));
    onSuccess();
  } catch (error) {
    yield put(updatePolicyHolderQuote.failure(error));
    onError();
  } finally {
    yield put(updatePolicyHolderQuote.fulfill());
  }
}

export function* handleUpdateCustomerQuotes({
  payload: { id, securityToken, updatedAddOns, policyType, quoteId, sucessCb },
}: {
  payload: {
    id: string;
    securityToken: string;
    updatedAddOns: Record<string, number>;
    policyType: string;
    quoteId: string;
    sucessCb?: () => void;
  };
  type: string;
}) {
  try {
    const locale: Record<string, any> = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ notify: 'updated', security_token: securityToken, view: 'account' });
    yield put(updateCustomerQuotes.request());

    const result: Record<string, any> = yield call(
      api.patch,
      `${getAPIHost(id)}/quotes/${id}/?${searchParams}`,
      false,
      {
        body: JSON.stringify({
          preview: false,
          policy_type: policyType,
          quote_id: quoteId,
          add_ons: decamelizeKeys(updatedAddOns),
        }),
      },
    );

    yield put(
      updateCustomerQuotes.success({
        id,
        locale,
        result,
      }),
    );

    sucessCb?.();
    return result;
  } catch (error) {
    yield put(updateCustomerQuotes.failure(error));
    return null;
  } finally {
    yield put(updateCustomerQuotes.fulfill());
  }
}

export function* handleUpdateHomeWarrantyQuote({
  payload: { quotePackageId, securityToken, quoteId, plan, updatedAddOns },
}: {
  payload: {
    quotePackageId: string;
    securityToken: string;
    quoteId: string;
    plan?: string;
    updatedAddOns?: Record<string, number>;
  };
  type: string;
}) {
  try {
    yield put(updateQuote.request());

    const locale: Record<string, unknown> = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ security_token: securityToken });
    const url = `${API_HOST!}/v1/quotes/${quotePackageId}/?${searchParams.toString()}`;

    const body = (() => {
      const obj: { policy_type: string; quote_id: string; plan?: string; add_ons?: Record<string, unknown> } = {
        policy_type: POLICY_TYPE.KW_HOME_WARRANTY,
        quote_id: quoteId,
      };
      if (plan) {
        obj.plan = plan;
      }
      if (updatedAddOns) {
        obj.add_ons = decamelizeKeys(updatedAddOns) as Record<string, number>;
      }
      return obj;
    })();
    const stringBody = JSON.stringify(body);

    const quotePackage: unknown = yield call(api.patch, url, false, { body: stringBody });

    yield put(
      updateQuote.success({
        id: quotePackageId,
        locale,
        quotePackage,
      }),
    );

    return quotePackage;
  } catch (error) {
    yield put(updateQuote.failure(error));
    return null;
  } finally {
    yield put(updateQuote.fulfill());
  }
}

export function* quotesSaga() {
  yield takeLatest(getQuote.TRIGGER, handleGetQuote);
  yield takeLatest(createQuote.TRIGGER, handleCreateQuote);
  yield takeLatest(getCustomerQuotes.TRIGGER, handleGetCustomerQuotes);
  yield takeLatest(updatePolicyHolderQuote.TRIGGER, handleUpdatePolicyHolderQuote);
  yield takeLatest(updateCustomerQuotes.TRIGGER, handleUpdateCustomerQuotes);
  yield takeLatest(updateQuote.TRIGGER, handleUpdateHomeWarrantyQuote);
}
