import { lazy } from 'react';
import { retry } from '../helpers/utils';
import { localePrefix } from './routeHelpers';

export const Esim = lazy(() => retry(() => import('../containers/pages/Esim' /* webpackChunkName: "Esim" */)));

export const routes = [
  {
    key: 'esim',
    path: `${localePrefix}/extras/esim/:bookingId`,
    exact: true,
    component: Esim,
  },
];
