import { loadFEMessages } from '../actions/intl';

const initialState = {
  slug: '',
  loading: false,
  error: null,
  loaded: [] as Array<string>,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case loadFEMessages.REQUEST:
      return { ...state, loading: true, error: null, slug: action.payload };

    case loadFEMessages.SUCCESS:
      return { ...state, loading: false, error: null, loaded: [...state.loaded, action.payload] };

    case loadFEMessages.FAILURE:
      return { ...state, loading: false, error: action.payload };

    case loadFEMessages.FULFILL:
      return { ...state, slug: '', loading: false };

    default:
      return state;
  }
};
