import { createRoutine } from 'redux-saga-routines';
import { takeLatest, call, put, select, StrictEffect } from 'redux-saga/effects';
import { get } from 'lodash/fp';
import api from '@whitelabel/xcover-shared/helpers/api';
import { LoadFEMessagesPayloadType } from '../helpers/types';

export const loadFEMessages = createRoutine('intl/LOAD_FE_MESSAGES');

export function* handleLoadFEMessages({
  payload: { slug },
}: LoadFEMessagesPayloadType): Generator<StrictEffect, void, any> {
  try {
    yield put(loadFEMessages.request(slug));
    const locale = yield select(get('intl.locale'));
    const currentMessages = yield select(get('intl.messages'));
    const feMessages = yield call(
      api.get,
      `${process.env.REACT_APP_MESSAGES_HOST}/messages/frontend/${slug}/?language=${locale}`,
      false,
    );
    yield put({
      type: '@@intl/UPDATE',
      payload: {
        messages: {
          ...currentMessages,
          ...feMessages,
        },
      },
    });
    yield put(loadFEMessages.success(slug));
  } catch (error) {
    yield put(loadFEMessages.failure(error));
  } finally {
    yield put(loadFEMessages.fulfill());
  }
}

export function* intlSaga() {
  yield takeLatest(loadFEMessages.TRIGGER, handleLoadFEMessages);
}
