import { createRoutine } from 'redux-saga-routines';
import { takeLatest, call, put, select } from 'redux-saga/effects';
import { get } from 'lodash/fp';
import api from '@whitelabel/xcover-shared/helpers/api';
import { getAPIHost } from '@whitelabel/xcover-shared/helpers/multiRegion';

export const getPartners = createRoutine('partners/GET_PARTNERS');
export const clearPartners = createRoutine('partners/CLEAR_PARTNERS');

function* handleGetPartners({ payload: customerID }: any) {
  try {
    // @ts-expect-error fix type for yield
    const locale = yield select(get('intl.locale'));
    const searchParams = new URLSearchParams({ language: locale });
    yield put(getPartners.request());
    // @ts-expect-error fix type for yield
    const partners = yield call(api.get, `${getAPIHost()}/customers/${customerID}/partners/?${searchParams}`, true);
    yield put(getPartners.success({ locale, partners }));
  } catch (error) {
    yield put(getPartners.failure(error));
  } finally {
    yield put(getPartners.fulfill());
  }
}

export function* partnersSaga() {
  yield takeLatest(getPartners.TRIGGER, handleGetPartners);
}
