import { takeLatest, put } from 'redux-saga/effects';
import { createRoutine } from 'redux-saga-routines';
import * as FullStory from '@fullstory/browser';
import { handleFormikAWSFormError } from '@whitelabel/xcover-shared/helpers/api';
import { IMPERSONATE } from '@whitelabel/xcover-shared/helpers/constants';

export const impersonateLogin = createRoutine('user/IMPERSONATE_LOGIN');

const tagFullStory = (username: string) => {
  const adminUsername = sessionStorage.getItem(IMPERSONATE.ADMIN_USERNAME);
  const customerEmail = sessionStorage.getItem(IMPERSONATE.CUSTOMER_EMAIL);
  FullStory.identify(username, {
    displayName: `Admin: ${adminUsername} as ${customerEmail}`,
  });
};

export function* handleImpersonateLogin({ payload: { username, email } }: any) {
  try {
    yield put(impersonateLogin.request());
    const user = {
      username,
      email,
    };
    sessionStorage.setItem(IMPERSONATE.CUSTOMER_EMAIL, email);
    if (!window.isHeadless && FullStory.isInitialized()) {
      tagFullStory(username);
    }
    yield put(impersonateLogin.success(user));
  } catch (error) {
    yield put(impersonateLogin.failure(handleFormikAWSFormError(error)));
  } finally {
    yield put(impersonateLogin.fulfill());
  }
}

export function* impersonateLoginSaga() {
  yield takeLatest(impersonateLogin.TRIGGER, handleImpersonateLogin);
}
