import api from '@whitelabel/xcover-shared/helpers/api';
import { FE_MESSAGES_SLUG } from '@whitelabel/xcover-shared/helpers/constants';
import { retry } from './utils';

export const loadMessages = (locale: string, requestInit?: RequestInit): any =>
  retry(() =>
    api.get(
      `${process.env.REACT_APP_MESSAGES_HOST}/messages/frontend/${FE_MESSAGES_SLUG}?language=${locale}`,
      false,
      requestInit || {},
    ),
  );
