import styled, { css, createGlobalStyle } from 'styled-components';
import { Navbar, NavbarToggler, Nav, Container, NavItem } from 'reactstrap';
import { Button } from '@whitelabel/component-library';
import { zIndex } from '@whitelabel/helpers/style/zIndex';
import { fontStyle, fontWeight, fontSize, fontFamily } from '@whitelabel/helpers/style/type';
import { gray, themeColor, color } from '@whitelabel/helpers/style/colors';
import { borderRadius, flipForRTL, flipFlexForRTL, getRightValue } from '@whitelabel/helpers/style/box';
import { mediaBreakpointDown, mediaBreakpointUp } from '@whitelabel/helpers/style/grid';
import { multiply } from '@whitelabel/helpers/style/units';
import { getThemeProp, button, transitions } from '@whitelabel/helpers/style/get';
import { plainLinkStyle } from '@whitelabel/xcover-shared/helpers/style/utils';
import { focusLinkStyle, focusVisibleMixin, focusLinkStyleOnBlackBG } from '@whitelabel/helpers/style/utils';

const mobileNavHeight = '4rem';
const hamburgerIcon = (strokeColor: string) =>
  // eslint-disable-next-line max-len
  `url("data:image/svg+xml;charset=utf8,%3Csvg width='24px' height='20px' viewBox='0 0 24 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='${strokeColor.replace(
    '#',
    '%23',
  )}' stroke-width='4' stroke-linecap='round' d='M2,2 L22,2 M2,10 L22,10 M2,18 L22,18'/%3E%3C/svg%3E")`;

export const GlobalStyle = createGlobalStyle<{ fixed?: boolean }>`
  body {
    position: ${({ fixed }) => (fixed ? 'fixed' : 'static')};
    width: 100%;
    text-align: start;
  }
`;

export const StyledDesktopGlobalNavBar = styled(Navbar)`
  display: flex;
  padding: 0;
  position: fixed;
  bottom: 0;
  z-index: ${zIndex('contentLayer')};
  width: 100%;
  background: ${themeColor('header')};

  ${mediaBreakpointDown('md')`
    display: none;
  `};

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.75rem auto;

    li {
      margin-bottom: 0;
      padding: 0;
      &:last-child a {
        margin-inline-end: 0;
      }

      ${mediaBreakpointUp('md')`
        margin: 0 1.5rem;
      `}

      ${mediaBreakpointUp('lg')`
        margin: 0 0 0 1.5rem;
      `}
    }

    li > a,
    li button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      color: ${color('white')};
      font-weight: ${fontWeight('bold')};
      font-size: ${multiply(fontSize('base'), 0.625)};
      text-transform: uppercase;
      background: none;
      border: 0;
      transition: color ${transitions('duration')} ease-in-out;
      > .nav-icon-wrapper {
        margin-inline-end: 0;
      }

      &:hover {
        color: ${themeColor('primary')};
        background: none;
        ${plainLinkStyle}
      }

      &:focus {
        outline: none;
      }

      &.active {
        background: none;
      }

      ${focusVisibleMixin(focusLinkStyleOnBlackBG)}

      svg {
        margin: 0 0 0.25rem;
      }

      ${mediaBreakpointUp('lg')`
        flex-direction: row;
        font-size: ${fontSize('sm')};

        svg {
          margin-top: 0;
          margin-bottom: 0;
          margin-inline-end: 0.25rem;
        }
      `}
    }

    ${mediaBreakpointUp('sm')`
      justify-content: space-around;
    `}

    ${mediaBreakpointUp('md')`
      justify-content: center;
    `}

    ${mediaBreakpointUp('lg')`
      justify-content: flex-end;
    `}
  }

  ${mediaBreakpointUp('lg')`
    position: static;
  `}

  @media print {
    display: none;
  }
`;

export const StyledNavBar = styled(Navbar).attrs((props) => ({
  light: !props.dark,
  expand: 'lg',
}))`
  z-index: ${zIndex('contentLayer')};
  padding: 0;
  background-color: ${color('white')};

  ${mediaBreakpointUp('md')`
    min-height: 2rem;
  `}

  .container-fluid {
    min-height: ${mobileNavHeight};
    padding: 0;
    max-width: 75rem;

    ${mediaBreakpointUp('md')`
      min-height: 3rem;
    `}

    ${mediaBreakpointUp('lg')`
      margin: 1rem auto;
      padding: 0 2rem;
    `}
  }
`;

export const StyledNavBarToggler = styled(NavbarToggler)`
  ${({ $active, theme }) =>
    $active
      ? css`
          background: ${themeColor('primary')};

          .navbar-light &&,
          .navbar-dark && {
            border-color: ${themeColor('primary')};
            .navbar-toggler-icon {
              background-image: ${hamburgerIcon(theme.nav.togglerIconColor.active)};
            }
          }
        `
      : css`
          .navbar-light &&,
          .navbar-dark && {
            .navbar-toggler-icon {
              background-image: ${hamburgerIcon(theme.nav.togglerIconColor.inactive)};
            }
          }
        `}

  padding: calc(0.625rem - ${getThemeProp('borderWidth')})
      calc(0.5rem - ${getThemeProp('borderWidth')});
  font-size: ${fontSize('base')};
  border-radius: ${borderRadius('base')};

  &:focus {
    box-shadow: none;
  }

  .navbar-toggler-icon {
    display: block;
    width: 1.5rem;
    height: 1.25rem;
  }
`;

export const StyledMobileTabletNavButtonWrapper = styled(Container)`
  ${({ $fixed }) =>
    $fixed &&
    css`
      position: fixed;
      bottom: 0;
      left: 0;
    `};

  ${mediaBreakpointUp('lg')`
    display:none;
  `}
`;

export const StyledNavButton = styled(Button)<{ $hideOnMobileAndTablet?: boolean }>`
  && {
    width: 100%;
    max-width: inherit;
    height: 2.5rem;
    padding-inline-end: 1rem;
    padding-inline-start: 1rem;
    font-weight: ${button('fontWeight')};
    font-size: ${fontSize('md')};

    ${mediaBreakpointDown('md')`
      margin-bottom: 1rem;
    `}

    ${mediaBreakpointUp('lg')`
      width: auto;
      margin-inline-start: 0.75rem;
    `}

    ${({ $hideOnMobileAndTablet }) =>
      $hideOnMobileAndTablet &&
      css`
        ${mediaBreakpointDown('md')`
          display: none;
        `};
      `}
  }
`;

export const StyledNavBox = styled.div<{ $secondary?: boolean; $hideOnDesktop?: boolean }>`
  position: relative;
  width: 100vw;
  height: calc(100vh - ${mobileNavHeight});
  /* arbitrary padding-bottom to handle Chrome UI hiding the logout link */
  padding: 1rem 1rem 3.5rem;
  overflow-y: auto;
  background: ${themeColor('header')};

  @media (min-height: 622px) {
    overflow-y: ${({ $secondary }) => ($secondary ? 'auto' : 'inherit')};
  }

  ${mediaBreakpointUp('md')`
    padding: 1.5rem 1.5rem 3.5rem;
  `}

  ${mediaBreakpointUp('lg')`
    ${({ $hideOnDesktop }: { $hideOnDesktop: boolean }) => $hideOnDesktop && 'display: none'};
    width: auto;
    height: auto;
    margin: 0 0 0 auto;
    padding: 0;
    background: none;
    align-items: center;
    overflow-y: inherit;
  `}
`;

export const StyledMenu = styled.div<{ $isOpen: boolean; $isSecondaryNavOpen: boolean; $rtl: boolean }>`
  ${mediaBreakpointDown('md')`
      position: fixed;
      top: 4rem;
      width: 100vw;
      ${getRightValue('-100vw')}
      opacity: 0;
      transition: opacity 0.35s;

      ${({ $isOpen }: { $isOpen: boolean }) =>
        $isOpen &&
        css`
          ${getRightValue('0vw')}
          opacity: 1;
        `};

      .StyledMenu-container {
        position: absolute;
        ${getRightValue('-100vw')}
        display: flex;
        width: 200vw;
        transition: right 0.35s, left 0.35s;

        ${({ $isOpen, $isSecondaryNavOpen }: { $isOpen: boolean; $isSecondaryNavOpen: boolean }) =>
          $isOpen &&
          $isSecondaryNavOpen &&
          css`
            ${getRightValue('0vw')}
          `};
        }
  `}
`;

export const StyledPrimaryNavItems = styled(Nav)<{ $secondary?: boolean; $rtl: boolean }>`
  display: flex;
  flex-direction: column;

  && {
    li > a,
    li > button,
    div > button {
      margin: 0;
      padding: 0;
      font-weight: ${fontWeight('bold')};
      font-size: ${multiply(fontSize('base'), 0.75)};
      text-transform: uppercase;
      svg {
        ${flipForRTL}
      }
      &:focus {
        outline: none;
      }
    }
  }

  ${mediaBreakpointDown('md')`
    li {
      padding: 0;
    }
    li:not(:last-child) {
      margin-bottom: 1rem;
    }

    button:not(.btn-primary) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: ${color('white')};
      background: none;
      border: 0;

      svg {
        margin: 0;
      }
    }
  `};

  ${mediaBreakpointUp('lg')`
  ${flipFlexForRTL}
    display: flex;
    margin: 0;
    border: 0;

    &&& {
      li > a,
      li > button,
      div > button {
        margin-inline-start: 1.5rem;
        font-size: ${multiply(fontSize('base'), 0.75)};
        transition: color ${transitions('duration')} ease-in-out;

        svg path {
          transition: stroke ${transitions('duration')} ease-in-out;
        }

        &:hover {
          color: ${themeColor('secondary')};
          ${plainLinkStyle}
          svg path {
            stroke: ${themeColor('secondary')};
          }
        }
      }

      li.show div > button {
        color: ${themeColor('secondary')};

        svg path {
          stroke: ${themeColor('secondary')};
        }
      }
    }
  `}
`;

export const StyledMobilePrimaryNavItems = styled.div<{ $rtl: boolean }>`
  display: flex;
  flex-direction: column;

  && {
    li > a,
    li > button,
    div > button {
      margin: 0;
      padding: 0;
      font-weight: ${fontWeight('bold')};
      font-size: ${multiply(fontSize('base'), 0.75)};
      text-transform: uppercase;
      svg {
        ${flipForRTL}
      }
      &:focus {
        outline: none;
      }
    }
    li {
      padding: 0;
    }
    li:not(:last-child) {
      margin-bottom: 1rem;
    }

    button:not(.btn-primary) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: ${color('white')};
      background: none;
      border: 0;

      svg {
        margin: 0;
      }
    }
  }

  ${mediaBreakpointUp('lg')`
    display: none;
  `}
`;

export const StyledDesktopPrimaryNavItems = styled(Nav)<{ $rtl: boolean }>`
  display: none;
  flex-direction: column;

  && {
    li > a,
    li > button,
    div > button {
      margin: 0;
      padding: 0;
      font-weight: ${fontWeight('bold')};
      text-transform: uppercase;
      svg {
        ${flipForRTL}
      }
      &:focus {
        outline: none;
      }
    }
  }

  ${mediaBreakpointUp('lg')`
  ${flipFlexForRTL}
    display: flex;
    margin: 0;
    border: 0;

    &&& {
      li > a,
      li > button,
      div > button {
        margin-inline-start: 1.5rem;
        font-size: ${multiply(fontSize('base'), 0.75)};
        transition: color ${transitions('duration')} ease-in-out;

        svg path {
          transition: stroke ${transitions('duration')} ease-in-out;
        }

        &:hover {
          color: ${themeColor('secondary')};

          svg path {
            stroke: ${themeColor('secondary')};
          }
        }
      }

      li.show div > button {
        color: ${themeColor('secondary')};

        svg path {
          stroke: ${themeColor('secondary')};
        }
      }
    }
  `}
`;

export const StyledMenuGlobalNavItems = styled(Nav)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  li {
    margin-bottom: 1rem;
    padding: 0;
    &:last-child a {
      margin-inline-end: 0;
    }
  }

  li > a,
  li button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin: 0;
    padding: 0;
    color: ${color('white')};
    font-weight: ${fontWeight('bold')};
    font-size: ${multiply(fontSize('base'), 0.75)};
    text-transform: uppercase;
    background: none;
    border: 0;

    &:hover {
      color: ${themeColor('primary')};
      background: none;
    }

    &:focus {
      outline: none;
    }

    &.active {
      background: none;
    }

    svg {
      min-width: 1rem;
      margin-top: 0;
      margin-inline-end: 0.6875rem;
      margin-bottom: 0.25rem;
      margin-inline-start: 0;
    }
  }
  ${mediaBreakpointUp('lg')`
      display: none;
  `};
`;

export const StyledNavSeperator = styled.hr`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-top: ${getThemeProp('borderWidth')} solid ${gray(600)};

  ${mediaBreakpointUp('lg')`
    display: none;
  `}
`;

export const StyledNavCustomer = styled.div`
  display: none;
  ${mediaBreakpointDown('md')`
      display: flex;
      flex-direction: column;
  `}
`;

export const StyledNavCustomerDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledNavCustomerDetails = styled.div`
  display: flex;
  flex-direction: column;
  overflow-wrap: anywhere;
`;

export const StyledNavCustomerIcon = styled.svg<{ $small?: boolean }>`
  max-width: 3rem;
  width: 100%;
`;

export const StyledNavCustomerName = styled.span`
  color: ${themeColor('primary')};
  ${fontStyle('paragraph')}
  ${mediaBreakpointUp('lg')`
    margin-inline-start: 0.5rem;
  `}
`;

export const StyledNavCustomerEmail = styled.span`
  color: ${color('white')};
  ${fontStyle('subtext')};
  font-family: ${fontFamily('serif')};
  ${mediaBreakpointUp('lg')`
    margin-inline-start: 0.5rem;
  `}
`;

export const StyledNavCustomerItems = styled(Nav)`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;

  li > a,
  li button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: ${color('white')};
    font-weight: ${fontWeight('bold')};
    font-size: ${multiply(fontSize('base'), 0.75)};
    text-transform: uppercase;

    svg {
      min-width: 1rem;
      margin-top: 0;
      margin-inline-end: 0.6875rem;
      margin-bottom: 0;
      margin-inline-start: 0;

      path {
        fill: ${color('white')};
      }
    }
  }
`;

export const StyledNavIcon = styled.svg`
  display: block;
  margin-inline-end: 0.5rem;
  color: ${color('white')};
  border-radius: 0.1875rem;
`;

export const StyledBrandWrapper = styled.div<{ $cobrand?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  padding: 0.75rem 1rem;

  ${mediaBreakpointUp('md')`
      padding: 0.75rem 1.5rem;
  `};

  ${mediaBreakpointUp('lg')`
      ${({ $cobrand }: { $cobrand: boolean }) => !$cobrand && `width: auto;`}
      padding: 0;
  `};
`;

// margin-inline-start: -0.33rem is to center the icon for rounding
export const StyledNavIconWrapper = styled.div`
  &&&& {
    margin-inline-end: 0.25rem;
  }
  width: 1rem;
  height: 1rem;
  overflow: hidden;
  border-radius: 50%;
  &&& > svg {
    margin-inline-start: -0.33rem;
    margin-top: -0.05rem;
  }

  > .flag-image {
    max-width: none;
    height: 1.2rem;
    margin-inline-start: -0.33rem;
    margin-top: -0.3rem;
  }
  ${mediaBreakpointDown('md')`
    &&&& {
      margin-inline-end: 0.6875rem;
    }
    margin-bottom: 0.25rem;
  `};
`;

const focusLinkStyleWithCustomColor = css`
  color: ${themeColor('header')};
  ${focusLinkStyle}
`;

export const StyledNavCustomButton = styled(Button)<{ $rtl?: boolean }>`
  &&& {
    display: flex;
    direction: ${({ $rtl }) => ($rtl ? 'rtl' : 'ltr')};
    flex-direction: row;
    justify-content: flex-start;
    font-size: ${fontSize('sm')};
    font-weight: ${fontWeight('bold')};
    color: ${color('white')};
    margin-bottom: 1rem;
    &:hover {
      color: ${color('white')};
    }
    &:active {
      && {
        color: ${color('white')};
      }
    }
    ${mediaBreakpointUp('lg')`
      color: ${themeColor('header')};
      margin-bottom: 0;
      margin-inline-start: 1.5rem;
      &:hover{
        color: ${themeColor('secondary')};
      }
      &:active {
        && {
          color: ${themeColor('secondary')};
        }
      }
      ${focusVisibleMixin(focusLinkStyleWithCustomColor)}
    `};
  }
`;

export const StyledMobileTabletProtectionItem = styled.li`
  ${mediaBreakpointUp('lg')`
    display: none;
  `};
`;

export const StyledCustomNavItem = styled(NavItem)`
  display: flex;
  padding-inline-start: 0;
`;
